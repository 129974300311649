// Scripts for navigation related functionality

import scrollLock from "scroll-lock/dist/scroll-lock";

// Prevent anchor tags from showing in URL. Using CSS smooth scrolling rather than a JS plugin.
let anchorLinks = document.querySelectorAll("a[href^=\'#\'] ");

anchorLinks.forEach(anchor => {
    anchor.addEventListener("click", function (event) {
        setTimeout(() => {
            history.replaceState(null, null, ' ');
        }, 5);
    });
});

const main = document.querySelector("main");
const nav = document.querySelector("nav");

if (!main.classList.contains("homepage")) {
    main.style.marginTop = `${nav.clientHeight}px`;

    window.addEventListener("resize", () => {
        main.style.marginTop = `${nav.clientHeight}px`;
    })
}

const hamburger = document.querySelector(".hamburger");
const hamburgerLines = hamburger.querySelectorAll(".hamburger__line");
const mobileNav = document.querySelector(".mobile-nav");
const closeBtn = mobileNav.querySelector(".close-btn");

hamburger.addEventListener("click", toggleMenu);
closeBtn.addEventListener("click", toggleMenu);

function toggleMenu() {
    hamburgerLines.forEach(line => {
        line.classList.toggle("active");
    })
    mobileNav.classList.toggle("visible");
    if (mobileNav.classList.contains("visible")) {
        scrollLock.disablePageScroll();
    } else {
        scrollLock.enablePageScroll();
    }
}