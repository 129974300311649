//Compile with esbuild - https://esbuild.github.io/

// Make or remove these depending on your project.
require('./src/mobile.js'); //Mobile scripts
require('./src/scrollEvents.js'); //Scroll events (GSAP)
//require('./src/custom-cursor.js'); //swiper.js
require('./src/carousels.js'); // Swiper scripts
require('./src/form.js'); // Form scripts
//require('./src/modals.js'); // Modal scripts
require('./src/navigation.js'); //Navigation scripts
// require('./src/cookies.js'); //Cookie scripts
require('./src/testimonials');
require('./src/loadmore');
require('./src/numbered-list-with-paras');

const r = document.querySelector(':root'); // For adding CSS variables to the root element via js

//Document loaded event
document.addEventListener("DOMContentLoaded", function () {
    scrollbarWidthSet(); // Set a CSS variable with the scrollbar width
    initScrollOut(); // Appear on scroll
    getWindowHeight(); // sets a variable for --vh - https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    splitText(); //Split text into spans, if applicable.
});

// Resize event
window.addEventListener('resize', () => {
    getWindowHeight();
});

function getWindowHeight() {
    let vh = window.innerHeight * 0.01;
    r.style.setProperty('--vh', `${vh}px`);
}

function scrollbarWidthSet() {
    // Set a CSS variable with the scrollbar width
    let scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';
    r.style.setProperty('--scrollbar-width', scrollbarWidth);
}

function initScrollOut() {
    // Appear on scroll, using scrollOut.js - https://scroll-out.github.io/
    // Add [data-scroll] to elements to animate on scroll, the states are controlled by CSS.
    // [data-scroll="in"] (On screen) and [data-scroll="out"]/[data-scroll] (Off screen)
    // Example: <h1 data-scroll>Heading</h1>

    ScrollOut({ // Only animates once
        targets: '[data-scroll]',
        offset: 0,
        threshold: 0.2,
        once: true,
    });
    ScrollOut({ //repeating
        targets: '[data-scroll-r]',
        once: false,
    });
}

function splitText() {
    // splitting.js - https://splitting.js.org/
    // Splits the letters of an element into spans, so they can be animated individually.
    // Example: <h1 data-splitting>Heading</h1>

    Splitting({
        by: 'chars'
    });
}

const text = document.querySelector(".accreditations .copy .subtitle");
const textAlt = document.querySelector(".image-and-text-block .copy h4")
if (text) {
    text.innerHTML = text.innerHTML.replace("Atkinson", "Atkinson<br/>")
} else if (textAlt) {
    textAlt.innerHTML = textAlt.innerHTML.replace("(Hons)", "(Hons)<br/>")
}