// Scroll events if any. Usually this will be scripts for GSAP scrolltrigger animations.

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const header = document.querySelector("#site-header");

if (header.classList.contains("homepage")) {
    // Fixed nav bar after scroll
    ScrollTrigger.create({
        trigger: ".hero-image",
        start: `bottom-=${header.clientHeight} top`,
        endTrigger: "html",
        end: "bottom top",
        toggleClass: {targets: header, className: "fixed"},
    })
}


// Pin journal filter buttons
const filterBtns = document.querySelectorAll(".alm-filter--inner");

filterBtns.forEach(filterBtns => {
    setTimeout(() => {
        ScrollTrigger.create({
            trigger: filterBtns,
            start: () => {
                return `top top+=${header.offsetHeight - 1}`
            },
            endTrigger: "#site-footer",
            end: "top 75%",
            pin: true,
            pinSpacing: false,
        })
    }, 500);

    filterBtns.addEventListener("click", () => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 500)
    })
})


const loadMoreBtns = document.querySelectorAll(".alm-load-more-btn");
loadMoreBtns.forEach(btn => {
    btn.addEventListener("click", () => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 500)
    })
});

// Fade in and up on scroll
let mm = gsap.matchMedia();

mm.add("(max-width: 800px)", () => {
    gsap.utils.toArray(".anim-fade-in-and-up-on-scroll:not(.done):not(.front-page-hero:first-of-type)").forEach((section) => {

        const tl = gsap.timeline({paused: true})

        tl.fromTo(section, {opacity: 0, y: '50px'}, {opacity: 1, y: '0', duration: 1.5, ease: "power2.out"});

        ScrollTrigger.create({
            animation: tl,
            trigger: section,
            start: 'top 90%',
            // invalidateOnRefresh: true,
            toggleClass: {targets: section, className: "done"}
        });
    });
})

mm.add("(min-width: 800px)", () => {
    gsap.utils.toArray(".anim-fade-in-and-up-on-scroll:not(.done):not(.front-page-hero:first-of-type)").forEach((section) => {

        const tl = gsap.timeline({paused: true})

        tl.from(section, {opacity: 0, y: '50px', lazy: false, duration: 1.5, ease: "power2.out"});

        ScrollTrigger.create({
            animation: tl,
            trigger: section,
            start: 'top 80%',
            toggleClass: {targets: section, className: "done"}
        });
    });
})


// Stagger testimonial circles
const circles = document.querySelectorAll(".circle");
if (circles.length > 0) {
    gsap.from(".circle", {
        y: 30,
        duration: 1.5,
        ease: "power4.out",
        stagger: {
            each: 0.05,
        },
        scrollTrigger: {
            trigger: ".circle-row",
            start: "top 80%",
        }
    })
}
