// Check if user is on mobile
function is_mobile() {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}


if( is_mobile() ) {
    window.is_touch = true; //Make it a global variable so that it can be used in other files
}

