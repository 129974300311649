import Swiper from "swiper/bundle";
import 'swiper/css';

const scrollbar = document.querySelector('.swiper-scrollbar');

const swiper = new Swiper(".swiper.journal-swiper", {
    spaceBetween: 23,
    slidesPerView: 'auto',
    allowTouchMove: true,
    snapOnRelease: true,
    // freeMode: true,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        dragSize: 130,
        snapOnRelease: false,
    },
    breakpoints: {
        800: {
            spaceBetween: '2.44%',
            scrollbar: {
                dragSize: 300,
            }
        },
        985: {
            spaceBetween: '2.44%',
            scrollbar: {
                dragSize: 682,
            }
        },
    }
});

export const testimonialSlider = new Swiper(".swiper.testimonial-slider", {

    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    allowTouchMove: false,
})

const initialCircles = document.querySelectorAll(".circle");