const postGrid = document.querySelector(".post-grid");

if (postGrid) {
    setTimeout(() => {
        postGrid.dataset.scroll = "in";
    }, 100)
}


// Show/hide first card in journal grid when clicking filter options
const categoryLinks = document.querySelectorAll(".all-posts .alm-filter--link");

if (categoryLinks) {
    categoryLinks.forEach(link => {
        link.addEventListener("click", (event) => {
            toggleFirstCard(event.target);
        });
    })
}

function toggleFirstCard(target) {
    if (!target.classList.contains("active")) {
        
    }
}