// // Usually this will be scripts Gravity forms or Contact form 7 WordPress plugins.
//

//Auto height on textarea box
const textarea = document.querySelector("#resizing");

if (textarea) {
    textarea.addEventListener('input', autoResize);
}

//
function autoResize() {
    this.style.height = 'auto';
    this.style.height = this.scrollHeight + 'px';
}


// Set focus state on inputs
const inputs = document.querySelectorAll(".wpcf7-form-control");

inputs.forEach((input) => {
    input.addEventListener("focusin", () => {
        setFocused(input);
    });
    input.addEventListener("focusout", () => {
        unsetFocused(input);
    });

})

function setFocused(input) {
    let computedStyle = getComputedStyle(input);
    let paddingBottom = parseInt(computedStyle.paddingBottom);

    input.parentElement.parentElement.classList.add("focused");
    input.style.height = `${input.clientHeight - 1}px`;
    input.style.paddingBottom = `${paddingBottom - 1}px`;

}

function unsetFocused(input) {
    let computedStyle = getComputedStyle(input);
    let paddingBottom = parseInt(computedStyle.paddingBottom);

    input.parentElement.parentElement.classList.remove("focused");
    input.style.height = `${input.clientHeight + 1}px`;
    input.style.paddingBottom = `${paddingBottom + 1}px`;
}