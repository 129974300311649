const initialsCircles = document.querySelectorAll(".circle");
const container = document.querySelector(".circle-row");
import {testimonialSlider} from "./carousels";


if (container) {
  initialsCircles.forEach((circle) => {
    circle.addEventListener("click", () => {
      const i = parseInt(circle.dataset.index);
      testimonialSlider.slideTo(i);
      changeActiveCircle(circle);
    })
  })

  function changeActiveCircle(circle) {
    initialsCircles.forEach((el) => {
      el.classList.remove("active");
    })
    circle.classList.add("active");
  }

  function setContainerWidth() {
    let width = (initialsCircles[0].clientWidth * initialsCircles.length) / 1.25;
    // let minWidth = initialsCircles[0].clientWidth * 6;
    container.style.width = `${width}px`;
    // if (initialsCircles.length < 5) {
    // container.style.minWidth = `${minWidth}px`;
    // }
  }

  if (initialsCircles.length > 4) {
    setContainerWidth();
  }
  window.addEventListener("resize", () => {
    if (initialsCircles.length > 4) {
      setContainerWidth();
      console.log("hello");
    }
  })
}